<template>
  <div class="item my-5">
    <b-row class="mb-4">
      <b-col cols="12" md="2">
        <h2>Budget</h2>
      </b-col>
      <b-col v-if="filteredCategories.length > 1" cols="12" md="4">
        <h2>Necessities: {{ formatcurency(NecessityTotal) }}</h2>
      </b-col>
      <b-col v-if="filteredCategories.length > 1" cols="12" md="6" class="d-flex">
        <b-form-input class="p-4" v-model="filter" placeholder="Search..."></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          <!-- <b-button class="ml-2" @click="shownew = !shownew">+</b-button> -->
          <b-button v-b-modal.NewRecuringTransaction>New Recurring Expense</b-button>
        </b-input-group-append>
      </b-col>
    </b-row>
    <b-row>
      <b-checkbox v-model="HideZeroBudgets" class="mb-2" switch>
        Hide Zero Budgets
      </b-checkbox>
    </b-row>
    <RecurringTransactions :EditRecurringTransaction="RecurringTransaction" :EnvelopeOptions="EnvelopeOptions" />
    <div v-if="filteredCategories.length < 1">
      <h3>
        No Categories to show...
      </h3>
    </div>
    <div v-bind:key="category.id" v-for="category in filteredCategories">
      <b-button @click="DeleteCategory.from = category; $bvModal.show('toCategory')">
        <b-icon-trash></b-icon-trash>
      </b-button>
      <h5 class="underline">{{ category.name }} - {{ formatcurency(Number(category.balance || 0)) }}
      </h5>
      <div v-if="category.envelopes < 1">
        <h5>No Envelopes in Category</h5>
      </div>
      <div v-for="envelope in category.envelopes" v-bind:key="envelope.id">
        <b-card>
          <b-input-group>
            <b-col md="2">
              <!-- <a class="envelope" @click="envelope.showtable = !envelope.showtable"> -->
              <h5>
                {{ envelope.name }}
              </h5>
              <!-- <b-form-group
              class="envelope"
              :label="envelope.name"
              label-for="input-name"
              @click="envelope.showtable = !envelope.showtable"
            ></b-form-group> -->
            </b-col>
            <b-col md="2" @click="envelope.showtable = !envelope.showtable">
              <b-checkbox @change="UpdateBudget(envelope.budget)"
                v-model="envelope.budget.necessity">Necessity</b-checkbox>
            </b-col>

            <b-col md="4">
              <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Budget"
                label-for="input-name">
                <money class="form-control" size="sm"
                  @blur.native="UpdateBudget(envelope.budget); updateCategoryBalances()"
                  v-bind:class="envelope.budget.amount < enveloperecurringtransactionsTotal(envelope) ? 'error' : 'success'"
                  v-model="envelope.budget.amount" id="input-name"></money>
              </b-form-group>
            </b-col>
            <b-col md="4">

              <b-row>
                <b-col cols="5">
                  <b-button @click="envelope.showtable = !envelope.showtable"
                    :disabled="envelope.recurringtransactions.length < 1">
                    {{ envelope.showtable ? 'Hide' : 'Show' }} {{ 'Expenses (' + envelope.recurringtransactions.length
                      + ')' }}
                  </b-button>
                </b-col>
                <b-col cols="7">
                  <h5 id="input-exptotal">
                    <!-- Total: {{ -->
                    {{
                      formatcurency(enveloperecurringtransactionsTotal(envelope))
                    }}
                  </h5>
                </b-col>

              </b-row>
            </b-col>

          </b-input-group>
          <b-table v-if="envelope.showtable" small striped hover outlined bordered responsive show-empty
            :items="envelope.recurringtransactions" :fields="recurringtransactionsfields" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :filter="filter">
            <template #empty="scope">
              <h5 style="text-align: center;">No Recurring Expenses to show...</h5>
            </template>

            <template v-slot:cell(payeeedit)="data">
              <div v-if="data.item.edit == true">
                <b-form-input v-model="data.item.payee" />
              </div>
              <div v-if="!data.item.edit">
                {{ data.item.payee }}
              </div>
            </template>
            <template v-slot:cell(AmountEdit)="data">
              <div v-if="data.item.edit == true">
                <money id="amount" class="form-control" v-model="data.item.amount">
                </money>
              </div>
              <div v-if="!data.item.edit">
                {{ formatcurency(data.item.amount) }}
              </div>
            </template>
            <template v-slot:cell(DescriptionEdit)="data">
              <div v-if="data.item.edit == true">
                <b-form-input id="description" class="form-control" v-model="data.item.description">
                </b-form-input>
              </div>
              <div v-if="!data.item.edit">
                {{ data.item.description }}
              </div>
            </template>
            <!-- <template v-slot:cell(FreqEdit)="data">
            <div v-if="data.item.edit == true">
              <model-select :options="recurringfreqOptions" v-model="data.item.recurringfreqId"></model-select>
            </div>
            <div v-if="!data.item.edit">
              {{ data.item.recurringfreq.name }}
            </div>
          </template> -->

            <template v-slot:cell(Actions)="data">
              <b-button-group>
                <b-button size="sm" @click="
                  saveExpense(data.item);
                data.item.edit = !data.item.edit;
                " class="mr-1">
                  <b-icon-pencil v-if="!data.item.edit" />
                  <b-icon-save v-if="data.item.edit" />a
                </b-button>
                <b-button size="sm" @click="Delete(data.item, data.index)" class="mr-1" variant="danger">
                  <b-icon-trash />
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-card>
       
      </div>
    </div>
    <b-modal :title="'Delete Category: '+DeleteCategory.from.name" @ok="DeleteCategoryAction" id="toCategory" @show="DeleteCategory.toEnvelopeId = null" :ok-disabled="!DeleteCategory.toEnvelopeId" >
          <p>
            In order to delete this category, all Envelopes will need their category transfered to another
            category.
          </p>
          <b-form-group label-cols-sm="4" label-cols-lg="4" content-cols-sm content-cols-lg="7" label="Target Category:"
            label-for="input-category">
            <model-select id="input-category" :options="DeleteCategoriesOptions" v-model="DeleteCategory.toEnvelopeId">
            </model-select>
          </b-form-group>
        </b-modal>
  </div>
</template>

<script>
import axios from "axios";
// import NewItem from "./NewItem.vue";
import { ModelSelect } from "vue-search-select";
import RecurringTransactions from "./RecurringTransactions.vue";
// import { CurrencyInput } from 'vue-currency-input'


export default {
  name: "Budget",
  components: { RecurringTransactions, ModelSelect },
  methods: {
    DeleteCategoryAction() {
      // if (item.envelopes.length > 0) {


      //   if (confirm("Delete Category?  " + item.name + " and all Envelopes?")) {
      //     axios.delete("/api/categories/" + item.id).then(() => {
      //       this.categories = this.categories.filter((c) => c.id !== item.id);
      //     });
      //   }


      // } else {
      //   if (confirm("Delete Category?  " + item.name )) {
        axios.delete(`/api/categories/${this.DeleteCategory.from.id}/${this.DeleteCategory.toEnvelopeId}` ).then(() => {
          this.refresh();
        });
      // }
      // }

      console.log(this.DeleteCategory)
    },
    updateCategoryBalances() {
      // console.log("updateCategoryBalances")
      this.categories.forEach((a) => {
        a.balance = 0;
        a.envelopes.forEach((b) => {
          if (b.budget) {
            a.balance += b.budget.amount || 0;
          }

        });
      });
    },
    saveExpense(i) {
      if (i.edit) {
        console.log(i);
        axios.post("/api/recurringtransactions", i);
      }
    },
    UpdateBudget(i) {
      // console.log(i);
      axios.post("/api/budget", i).then((i) => { });
    },
    enveloperecurringtransactionsTotal(array) {
      let sum = 0;
      array.recurringtransactions.forEach((item) => {
        sum += Number(item.amount);
      });
      return sum;
    },
    findExpenseIndex(categoryId, envelopeId, expenseId) {
      console.log({ categoryId, envelopeId, expenseId });
      var catindex = this.categories.findIndex((c) => {
        return c.id == categoryId;
      });
      console.log(catindex);
      var envindex = this.categories[catindex].envelopes.findIndex((e) => {
        return e.id == envelopeId;
      });
      var expindex = this.categories[catindex].envelopes[
        envindex
      ].recurringtransactions.findIndex((e) => {
        return e.id == expenseId;
      });
      return {
        categoryIndex: catindex,
        envelopeIndex: envindex,
        expenseIndex: expindex,
      };
    },
    async Delete(i, index) {
      var index = this.findExpenseIndex(
        i.envelope.categoryId,
        i.envelopeId,
        i.id
      );

      console.log(index);

      if (
        confirm(
          `Delete expense? ${i.payee} ${i.description} $${i.amount}`
        )
      ) {
        axios
          .delete(
            "/api/recurringtransactions",
            { data: i }
          )
          .then(
            this.categories[index.categoryIndex].envelopes[
              index.envelopeIndex
            ].recurringtransactions.splice(index.expenseIndex, 1)
          );
      }
    },
    // async addItemInTable() {
    //   var NewExpense = this.NewExpense[0];

    //   axios.post("/api/recurringtransactions", [NewExpense]).then((d) => {
    //     var envelope = this.envelopes.filter((i) => {
    //       return i.id == NewExpense.envelopeId;
    //     });
    //     var recurringfreq = this.recurringfreq.filter((i) => {
    //       return i.id == NewExpense.recurringfreqId;
    //     });
    //     console.log(envelope, recurringfreq);
    //     var index = this.findExpenseIndex(
    //       envelope[0].categoryId,
    //       NewExpense.envelopeId
    //     );
    //     this.categories[index.categoryIndex].envelopes[
    //       index.envelopeIndex
    //     ].recurringtransactions.push({
    //       name: NewExpense.name,
    //       recurringfreqId: recurringfreq[0].id,
    //       recurringfreq: recurringfreq[0],
    //       amount: NewExpense.amount,
    //       envelopeId: NewExpense.envelope.id,
    //       envelope: envelope[0],
    //       edit: false,
    //     });
    //     this.NewExpense = [
    //       {
    //         name: "",
    //         recurringfreq: {},
    //         amount: "0",
    //         envelope: {},
    //       },
    //     ];
    //   });
    // },
    // updateRecurringTransactions() {
    //   axios.get("/api/recurringtransactions").then((res) => {
    //     this.recurringtransactions = res.data.map((i) => {
    //       var b = { ...i, edit: false };
    //       b.envelope.text = `${b.envelope.category.name} | ${b.envelope.name}`;
    //       b.envelope.value = {
    //         id: b.envelope.id,
    //         categoryId: b.envelope.category.id,
    //         name: b.envelope.name,
    //         description: b.envelope.description,
    //         categoryId: b.envelope.category.id,
    //         category: b.envelope.category,
    //       };
    //     });
    //   });
    // },

    async refresh() {
      // this.updateRecurringTransactions();
      // axios.get("/api/recurringtransactions").then((res) => {
      //   this.recurringtransactions = res.data.map((i) => {
      //     var b = { ...i, edit: false };
      //     b.envelope.text = `${b.envelope.category.name} | ${b.envelope.name}`;
      //     b.envelope.value = {
      //       id: b.envelope.id,
      //       categoryId: b.envelope.category.id,
      //       name: b.envelope.name,
      //       description: b.envelope.description,
      //       categoryId: b.envelope.category.id,
      //       category: b.envelope.category,
      //     };

      //     return b;
      //   });
      // });
      axios.get("/api/envelopes").then((res) => (this.envelopes = res.data));
      // axios
      //   .get("/api/recurringfreq")
      //   .then((res) => (this.recurringfreq = res.data));
      axios.get("/api/categories").then((res) => {
        var a = res.data;
        a.forEach((b) => {
          b.envelopes.forEach((c) => {
            if (!c.budget) {
              c.budget = { amount: 0, envelopeId: c.id };
            }
            c.showtable = false;
            c.budget.amount = Number(c.budget.amount);

            c.recurringtransactions.forEach((d) => {
              d.edit = false;
            });
          });
        });

        this.categories = res.data;
        // Add a new property to the first category
        if (this.categories[0]) {
          this.$set(this.categories[0], 'balance', null);
        }
      })
        .then(() => {
          this.updateCategoryBalances();
        });

    },
  },
  data() {
    return {
      DeleteCategory: {
        from: {},
        toEnvelopeId: null,
      },
      HideZeroBudgets: false,
      RecurringTransaction: {},
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      sortBy: "date",
      sortDesc: false,
      shownew: false,
      sortDirection: "asc",
      filter: null,
      NewExpense: [
        {
          name: "",
          recurringfreq: {},
          amount: "0",
          envelope: {},
        },
      ],
      categories: [],
      recurringfreq: [],
      envelopes: [],
      recurringtransactions: [],
      recurringtransactionsfields: [
        {
          key: "PayeeEdit",
          label: "Payee",
          class: "col-3",
          sortDirection: "desc",
        },

        // {
        //   key: "FreqEdit",
        //   label: "Frequency",
        //   variant: "none",
        //   class: "col-1",
        // },

        {
          key: "DescriptionEdit",
          label: "Description",
          variant: "none",
          class: "col-2",
        },

        {
          key: "AmountEdit",
          label: "Amount",
          variant: "none",
          class: "col-2",
        },

        {
          key: "Actions",
          label: "",
          variant: "none",
          class: "col-1",
        },
      ],
      NewExpensefields: [
        {
          key: "nameedit",
          label: "Name",
          class: "col-3",
          sortDirection: "desc",
        },

        {
          key: "FreqEdit",
          label: "Frequency",
          variant: "none",
          class: "col-1",
        },

        {
          key: "amountedit",
          label: "Amount",
          variant: "none",
          class: "col-2",
        },
        {
          key: "EnvEdit",
          label: "Envelope",
          variant: "none",
          class: "col-2",
        },
        {
          key: "Actions",
          label: "",
          variant: "none",
          class: "col-1",
        },
      ],
    };
  },
  created() {
    this.refresh();
  },
  computed: {
    DeleteCategoriesOptions() {

      if (this.categories && this.DeleteCategory.from.id) {
        let a = this.categories.filter(
          (i) => i.id !== this.DeleteCategory.from.id
        );
        a = a.map((i) => {
          return {
            value: i.id,
            text: i.name,
          };
        });
        a.unshift({ value: null, text: "-- Select Category --" });
        return a;
      } else {
        return { value: null, text: "None" };
      }

    },
    filteredCategories() {
      var results = this.categories
      if (this.HideZeroBudgets) {
        results = results.map((a) => {
          return {
            ...a,
            envelopes: a.envelopes.filter((b) => {
              return b.budget.amount > 0;
            }),
          };
        });
      }
      if (this.filter) {
        console.log("testing")
        console.log(this.HideZeroBudgets)
        if (this.HideZeroBudgets) {
          results = results.map((a) => {
            return {
              ...a,
              envelopes: a.envelopes.filter((b) => {
                return b.name.toLowerCase().includes(this.filter.toLowerCase()) && b.budget.amount > 0;
              }),
            };
          });
        }
        console.log("testing")
        return results.map((a) => {
          return {
            ...a,
            envelopes: a.envelopes.filter((b) => {
              return b.name.toLowerCase().includes(this.filter.toLowerCase());
            }),
          };
        });
      } else {
        return results;
      }
    },
    NecessityTotal() {
      let sum = 0;
      this.categories.forEach((a) => {
        a.envelopes.forEach((b) => {
          if (b.budget?.necessity) {
            sum += Number(b.budget.amount);
          }
        });
      });
      return Number(sum.toFixed(2));
    },
    EnvelopeOptions() {
      if (this.envelopes) {
        return [{
          value: null,
          text: "-- Select Envelope --",
        }].concat(this.envelopes.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        })));
      } else {
        return [{
          value: null,
          text: "None",
        }];
      }
    },
    // recurringfreqOptions() {
    //   if (this.recurringfreq) {
    //     return this.recurringfreq.map((i) => ({
    //       ...i,
    //       value: i.id,
    //       text: `${i.name}`,
    //     }));
    //   } else {
    //     return { value: null, text: "None" };
    //   }
    // },
  },
};
</script>
<style scoped></style>

<style scoped>
.bottom-row-style {
  padding: 15px !important;
  background-color: #ffffff;
}

.bottom-row-style-button {
  padding: 15px 0 15px 5px !important;
  background-color: #ffffff;
}

.table-scroll-fix .table-responsive {
  overflow-x: unset !important;
}

.underline {
  text-decoration: underline;
  font-weight: bold;
}

.envelope {
  cursor: pointer;
}
</style>
