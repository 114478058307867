<template>
  <div class="item my-5">
    <b-row class="mb-4">
      <b-col cols="12" md="12">
        <h1>Incomes</h1>
        <b-col cols="12" md="6" class="d-flex">
          <b-form-input class="p-4" v-model="incomeFilter" placeholder="Search..."></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!incomeFilter" @click="incomeFilter = ''">Clear</b-button>
            <!-- <b-button class="ml-2" @click="shownew = !shownew">+</b-button> -->
            <b-button v-b-modal.NewIncome>New Income</b-button>
          </b-input-group-append>
        </b-col>
        <b-table small striped hover outlined bordered responsive show-empty :items="incomes" :filter="incomeFilter"
          :fields="IncomeTableFields">
          <template v-slot:cell(AccountEdit)="data">
            <div v-if="data.item.edit == true">
              <model-select id="input-envelope" :options="AccountOptions" v-model="data.item.accountId"></model-select>
            </div>
            <div v-else>
              {{ data.item.account?.name || "" }}
            </div>
          </template>
          <template v-slot:cell(NameEdit)="data">
            <div v-if="data.item.edit == true">
              <b-form-input v-model="data.item.name" />
            </div>
            <div v-if="!data.item.edit">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(AmountEdit)="data">
            <div v-if="data.item.edit == true">
              <money id="amount" class="form-control" v-model="data.item.amount">
              </money>
            </div>
            <div v-if="!data.item.edit">
              {{ formatcurency(data.item.amount) }}
            </div>
          </template>
          <template v-slot:cell(DescriptionEdit)="data">
            <div v-if="data.item.edit == true">
              <b-form-input id="description" class="form-control" v-model="data.item.description">
              </b-form-input>
            </div>
            <div v-if="!data.item.edit">
              {{ data.item.description }}
            </div>
          </template>
          <template v-slot:cell(Actions)="data">
            <b-button-group>
              <b-button size="sm" @click="
                // saveExpense(data.item);
                data.item.edit = !data.item.edit;
              " class="mr-1">
                <b-icon-pencil v-if="!data.item.edit" />
                <b-icon-save v-if="data.item.edit" />
              </b-button>
              <b-button size="sm" @click="Delete(data.item, data.index)" class="mr-1" variant="danger">
                <b-icon-trash />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <NewIncome :EditIncome="EditIncome" />

    <b-row class="mb-4">
      <b-col cols="12" md="6">
        <h2>Funding Profiles</h2>
      </b-col>
      <b-col cols="12" md="6" class="d-flex">
        <b-form-input class="p-4" v-model="fundingProfileFilter" placeholder="Search..."></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!fundingProfileFilter" @click="fundingProfileFilter = ''">Clear</b-button>
          <b-button class="ml-4" id="popover-SplitBetween">Split Between</b-button>
        </b-input-group-append>
        <b-popover target="popover-SplitBetween" triggers="click blur" placement="top">
          <template #title>Split Between</template>
          <b-form-group label="Select profiles to auto split your budget amount with:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="checkbox-group-2" v-model="ProfilesToSplitWith"
              :aria-describedby="ariaDescribedby" name="flavour-2">
              <b-form-checkbox v-for="n in profileCount" :key="n" :text="`Profile ${n}`" :value="n">
                Profile {{ n }}
              </b-form-checkbox>
            </b-form-checkbox-group>
            <b-button small @click="SplitBetween">Split</b-button>
          </b-form-group>
        </b-popover>
      </b-col>
    </b-row>

    <b-checkbox v-model="HideZeroBudgets" class="mb-2" switch>
      Hide Zero Budgets
    </b-checkbox>

    <b-table small striped hover outlined bordered responsive show-empty :items="FilteredEnvelopes"
      :fields="envelopefields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="fundingProfileFilter">
      <template v-slot:cell(budgetamount)="data">
        {{ formatcurency(data.item.budget.amount) }}
      </template>
      <template v-slot:head(profile1)="data">
        <!-- <div :class="{'text-warning': TotalProfileIgnoreSelected.length > 0}"> -->
        Profile 1
        <b-button @click="ResetIncomeProfile(1)" variant="error" id="popover-TotalIgnore" size="sm">
          <b-icon-arrow-counterclockwise />
        </b-button>
      </template>
      <template v-slot:cell(profile1)="data">
        <money class="form-control" @blur.native="UpdateProfile(data.item.id, 1, data.item.profiles[0].amount)"
          v-model="data.item.profiles[0].amount" />
      </template>
      <template v-slot:head(profile2)="data">
        <!-- <div :class="{'text-warning': TotalProfileIgnoreSelected.length > 0}"> -->
        Profile 2
        <b-button @click="ResetIncomeProfile(2)" variant="error" id="popover-TotalIgnore" size="sm">
          <b-icon-arrow-counterclockwise />
        </b-button>
      </template>
      <template v-slot:cell(profile2)="data">
        <money class="form-control" @blur.native="UpdateProfile(data.item.id, 2, data.item.profiles[1].amount)"
          v-model="data.item.profiles[1].amount" />
      </template>
      <template v-slot:head(profile3)="data">
        <!-- <div :class="{'text-warning': TotalProfileIgnoreSelected.length > 0}"> -->
        Profile 3
        <b-button @click="ResetIncomeProfile(3)" variant="error" id="popover-TotalIgnore" size="sm">
          <b-icon-arrow-counterclockwise />
        </b-button>
      </template>
      <template v-slot:cell(profile3)="data">
        <money class="form-control" @blur.native="UpdateProfile(data.item.id, 3, data.item.profiles[2].amount)"
          v-model="data.item.profiles[2].amount" />
      </template>
      <template v-slot:head(profile4)="data">
        <!-- <div :class="{'text-warning': TotalProfileIgnoreSelected.length > 0}"> -->
        Profile 4
        <b-button @click="ResetIncomeProfile(4)" variant="error" id="popover-TotalIgnore" size="sm">
          <b-icon-arrow-counterclockwise />
        </b-button>
      </template>
      <template v-slot:cell(profile4)="data">
        <money class="form-control" @blur.native="UpdateProfile(data.item.id, 4, data.item.profiles[3].amount)"
          v-model="data.item.profiles[3].amount" />
      </template>
      <template v-slot:head(profile5)="data">
        <!-- <div :class="{'text-warning': TotalProfileIgnoreSelected.length > 0}"> -->
        Profile 5
        <b-button @click="ResetIncomeProfile(5)" variant="error" id="popover-TotalIgnore" size="sm">
          <b-icon-arrow-counterclockwise />
        </b-button>
      </template>
      <template v-slot:cell(profile5)="data">
        <money class="form-control" @blur.native="UpdateProfile(data.item.id, 5, data.item.profiles[4].amount)"
          v-model="data.item.profiles[4].amount" />
      </template>
      <template v-slot:head(ProfileTotal)="data">
        <b-popover target="popover-TotalIgnore" triggers="click blur" placement="top">
          <template #title>Ignore Profile</template>
          <b-form-group label="Select profiles for the total to ignore:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="checkbox-group-2" v-model="TotalProfileIgnoreSelected"
              @change="SaveProfileIgnore()" :aria-describedby="ariaDescribedby" name="flavour-2">
              <b-form-checkbox v-for="n in profileCount" :key="n" :text="`Profile ${n}`" :value="n">
                Profile {{ n }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-popover>
        <!-- <div :class="{'text-warning': TotalProfileIgnoreSelected.length > 0}"> -->
        Total
        <b-button :variant="TotalProfileIgnoreSelected.length > 0 ? 'warning' : 'error'" id="popover-TotalIgnore"
          size="sm">
          <b-icon-exclamation-circle />
        </b-button>
      </template>
      <template v-slot:cell(ProfileTotal)="data">
        <p>
          {{
            formatcurency(
              data.item.profiles
                .filter((_, i) => !TotalProfileIgnoreSelected.includes(i + 1))
                .map(profile => Number(profile.amount || 0))
                .reduce((a, b) => a + b, 0)
            )
          }}
        </p>
      </template>
      <template v-slot:cell(Actions)>
        <b-button size="sm" @click="addItemInTable()">
          <b-icon-save />
        </b-button>
      </template>
      <template v-slot:cell(FreqEdit)="data">
        <model-select :options="recurringfreqOptions" v-model="data.item.recurringfreqId">
        </model-select>
      </template>
      <template v-slot:cell(EnvEdit)="data">
        <model-select :options="EnvelopeOptions" v-model="data.item.envelopeId">
        </model-select>
      </template>
    </b-table>
    <!-- <h4>Totals</h4> -->
    <b-table small striped hover outlined bordered responsive show-empty :items="Totals" :fields="totalfields">
      <template v-slot:cell(second)="data">
        <div v-if="data.item.name == 'Profile Totals' || data.item.name == 'Remaining'
        ">
        </div>
        <div v-else>
          <b-checkbox @change="UseIncomeTotalsChanged" v-model="UseIncomeTotals" name="UseIncomeTotals">
            Use total of Incomes?
          </b-checkbox>

        </div>
      </template>
      <template v-slot:cell(third)="data">
        <div v-if="data.item.name == 'Profile Totals' || data.item.name == 'Remaining'
        ">
        </div>
        <div v-else>
          <p>
            {{ formatcurency(IncomesTotal || 0) }}
          </p>
        </div>
      </template>
      <template v-slot:cell(profile1total)="data">
        <div v-if="data.item.name == 'Profile Totals' || data.item.name == 'Remaining'
        ">
          {{ formatcurency(data.item.profile1 || 0) }}
        </div>
        <div v-else>
          <money :disabled="UseIncomeTotals" @blur.native="
            UpdateIncomeAmount(1, data.item.profile1);
          TotalSetting();
          " class="form-control" v-model="data.item.profile1" />
        </div>
      </template>
      <template v-slot:cell(profile2total)="data">
        <div v-if="data.item.name == 'Profile Totals' || data.item.name == 'Remaining'
        ">
          {{ formatcurency(data.item.profile2 || 0) }}
        </div>
        <div v-else>
          <money :disabled="UseIncomeTotals" @blur.native="
            UpdateIncomeAmount(2, data.item.profile2);
          TotalSetting();
          " class="form-control" v-model="data.item.profile2" />
        </div>
      </template>
      <template v-slot:cell(profile3total)="data">
        <div v-if="data.item.name == 'Profile Totals' || data.item.name == 'Remaining'
        ">
          {{ formatcurency(data.item.profile3 || 0) }}
        </div>
        <div v-else>
          <money :disabled="UseIncomeTotals" @blur.native="
            UpdateIncomeAmount(3, data.item.profile3);
          TotalSetting();
          " class="form-control" v-model="data.item.profile3" />
        </div>
      </template>
      <template v-slot:cell(profile4total)="data">
        <div v-if="data.item.name == 'Profile Totals' || data.item.name == 'Remaining'
        ">
          {{ formatcurency(data.item.profile4 || 0) }}
        </div>
        <div v-else>
          <money :disabled="UseIncomeTotals" @blur.native="
            UpdateIncomeAmount(4, data.item.profile4);
          TotalSetting();
          " class="form-control" v-model="data.item.profile4" />
        </div>
      </template>
      <template v-slot:cell(profile5total)="data">
        <div v-if="data.item.name == 'Profile Totals' || data.item.name == 'Remaining'
        ">
          {{ formatcurency(data.item.profile5 || 0) }}
        </div>
        <div v-else>
          <money :disabled="UseIncomeTotals" @blur.native="
            UpdateIncomeAmount(5, data.item.profile5);
          TotalSetting();
          " class="form-control" v-model="data.item.profile5" />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from "axios";
import RecurringTransactions from "./RecurringTransactions.vue";
import NewIncome from "./NewIncome.vue";

import { ModelSelect } from "vue-search-select";
export default {
  name: "Income",
  components: { RecurringTransactions, NewIncome, ModelSelect },
  methods: {
    UseIncomeTotalsChanged() {
      var amount = 0;
      if (this.UseIncomeTotals) {
        amount = this.IncomesTotal
      }
      for (let i = 1; i <= this.profileCount; i++) {
          this.UpdateIncomeAmount(i, amount);
        }
      this.TotalSetting();


    },
    Delete(item, index) {
      axios.delete(`/api/incomes/${item.id}`).then((i) => {
        this.incomes.splice(index, 1);
      });
    },
    ResetIncomeProfile(incomeProfileId) {
      console.log(incomeProfileId);
      let changedProfiles = [];
      this.envelopes.forEach(envelope => {
        envelope.profiles.forEach(profile => {
          if (profile.profile === incomeProfileId) {
            profile.amount = 0;
            changedProfiles.push({ envelopeId: envelope.id, profile: profile.profile, amount: profile.amount });
          }
        });
      });
      this.TotalSetting();
      if (changedProfiles.length > 0) {
        axios.post(`/api/incomeprofiles/bulk`, changedProfiles)
          .then((i) => {
            setTimeout(
              function () {
                this.refresh();
              }.bind(this),
              200
            );
          });
      }
    },
    SaveProfileIgnore() {
      console.log(this.TotalProfileIgnoreSelected);

      axios.post(`/api/settings/IncomeProfileTotalIgnore`, { value: this.TotalProfileIgnoreSelected });

    },
    UpdateIncomeAmount(i, amount) {
      // console.log("item");
      // console.log(item);
      // var data = { profileid: i, amount: Number(item[`profile${i}`]).toFixed(2) };
      var data = { profileid: i, amount: Number(amount).toFixed(2) };
      // data.push({ profileid: 2, amount: Number(item.profile2).toFixed(2) });
      // data.push({ profileid: 3, amount: Number(item.profile3).toFixed(2) });
      // item.forEach((i) => {
      //   data.push({ profileid: i.d, amount: dfd });
      // });
      // console.log(data);
      axios.post(`/api/income`, data);
    },

    SplitBetween() {
      this.envelopes.forEach((e) => {
        var EvenAmount = Math.ceil(Number(e.budget.amount / this.ProfilesToSplitWith.length) * 100) / 100;
        console.log(this.ProfilesToSplitWith)
        this.ProfilesToSplitWith.forEach((i) => {
          if (!e.profiles) {
            this.$set(e, 'profiles', []);
          }
          this.$set(e.profiles, i - 1, {
            envelopeId: e.id,
            profile: i,
            amount: EvenAmount,
          });
        });
      });
      axios.post(`/api/incomeprofiles/bulk`, this.envelopes.flatMap(e => e.profiles.map(p => ({ envelopeId: e.id, profile: p.profile, amount: p.amount })))).then((i) => {
        setTimeout(
          function () {
            this.refresh();
          }.bind(this),
          200
        );
      });
    },

    // SplitEvenly() {
    //   var data = [];
    //   // axios.get("/api/budget").then((res) => {
    //   //   res.data.forEach((b) => {
    //   //     console.log(b);
    //   //     var EvenAmount = Math.ceil(Number(b.amount / 2) * 100) / 100;
    //   //     console.log(EvenAmount);
    //   //     data.push({
    //   //       envelopeId: b.envelopeId,
    //   //       amount: EvenAmount,
    //   //     });
    //   //   });

    //   this.envelopes.forEach((e) => {
    //     var EvenAmount = Math.ceil(Number(e.budget.amount / 2) * 100) / 100;
    //     data.push({
    //       envelopeId: e.id,
    //       amount: EvenAmount,
    //     });
    //     //console.log(data);
    //   });
    //   axios.post(`/api/incomeprofile1`, data);
    //   axios.post(`/api/incomeprofile2`, data).then((i) => {
    //     setTimeout(
    //       function () {
    //         //console.log("test");
    //         this.refresh();
    //       }.bind(this),
    //       1000
    //     );
    //   });
    // },
    UpdateProfile(id, i, amount) {
      //console.log(data);
      axios.post(`/api/incomeprofiles`, { envelopeId: id, profile: i, amount: amount });
      this.TotalSetting();
    },
    ProfileTotal(i) {
      let sum = 0;
      this.envelopes.forEach((item) => {
        sum += Number(item.profiles[Number(i) - 1].amount);
      });
      return sum;
    },
    TotalSetting() {
      setTimeout(() => {
        axios.get("/api/income").then((res) => {
          this.income = res.data;
          this.Totals = [];

          let profileTotals = { name: "Profile Totals" };
          let incomeTotals = { name: "Income" };
          let remainingTotals = { name: "Remaining" };

          for (let i = 1; i <= this.profileCount; i++) {
            profileTotals[`profile${i}`] = this.ProfileTotal(i);
            if (this.UseIncomeTotals) {
              incomeTotals[`profile${i}`] = this.IncomesTotal;
            } else {
              incomeTotals[`profile${i}`] = Number(
                this.income.filter((item) => item.profileid == i)[0]?.amount
              ).toFixed(2) || 0;
            }
            // incomeTotals[`profile${i}`] = Number(
            //   this.income.filter((item) => item.profileid == i)[0]?.amount
            // ).toFixed(2) || 0;
            remainingTotals[`profile${i}`] = incomeTotals[`profile${i}`] - profileTotals[`profile${i}`];
          }

          this.Totals.push(profileTotals);
          this.Totals.push(incomeTotals);
          this.Totals.push(remainingTotals);
        });
      }, 1000);
    },
    getIncome() {
      axios.get("/api/income").then((res) => {
        this.income = res.data;
      });
    },
    refresh() {
      axios.get("/api/Accounts").then((res) => (this.Accounts = res.data));

      axios.get("/api/settings").then((res) => {
        this.profileCount = res.data?.profileCount || 1;

        if (res.data?.IncomeProfileTotalIgnore) {
          this.TotalProfileIgnoreSelected = (res.data?.IncomeProfileTotalIgnore
            .filter((num) => Number(num) <= this.profileCount)
            .map((num) => Number(num)));
        } else {
          this.TotalProfileIgnoreSelected = [];
        }

        // this.TotalProfileIgnoreSelected = (res.data?.IncomeProfileTotalIgnore
        //   .filter((num) => Number(num) <= this.profileCount)
        //   .map((num) => Number(num))) || [];

        this.profilefields = this.profilefields.slice(0, this.profileCount);
        this.profileTotalfields = this.profileTotalfields.slice(0, this.profileCount);

        //console.log(res.data)
      });
      axios.get("/api/income").then((res) => {
        this.income = res.data;
        //console.log(res.data)
      });
      axios.get("/api/incomes").then((res) => {
        this.incomes = res.data.map((i) => {

          return {
            ...i,
            edit: false,
          };
        });
        //console.log(res.data)
      });
      axios
        .get("/api/envelopes")
        .then((res) => {
          // console.log(res.data);
          this.envelopes = res.data.map((i) => {
            if (!i.budget) {
              i.budget = { amount: 0 };
            }
            //console.log(i.budget.amount)
            i.budget.amount = Number(i.budget.amount).toFixed(2);

            // if (!i.profiles) {
            //   i.profiles = Array.from({ length: this.profileCount }, (_, i) => ({ envelopeId: i.id, profile: i + 1, amount: 0 }));
            // } else {
            //   i.profiles = i.profiles.slice(0, this.profileCount);
            // }

            // if (!i.incomeProfile1) {
            //   i.incomeProfile1 = {
            //     amount: 0,
            //     envelopeId: i.id,
            //   };
            // } else {
            //   //console.log(i.incomeProfile1.amount)
            //   i.incomeProfile1.amount = Number(i.incomeProfile1.amount).toFixed(
            //     2
            //   );
            // }
            // if (!i.incomeProfile2) {
            //   i.incomeProfile2 = {
            //     amount: 0,
            //     envelopeId: i.id,
            //   };
            // } else {
            //   i.incomeProfile2.amount = Number(i.incomeProfile2.amount).toFixed(
            //     2
            //   );
            // }
            // if (!i.incomeProfile3) {
            //   i.incomeProfile3 = {
            //     amount: 0,
            //     envelopeId: i.id,
            //   };
            // } else {
            //   i.incomeProfile3.amount = Number(i.incomeProfile3.amount).toFixed(
            //     2
            //   );
            // }


            if (i.profiles) {
              i.profiles = i.profiles.slice(0, this.profileCount);
            }

            return i;
          });
        })
        .then(this.TotalSetting());
    },
  },
  data() {
    return {
      HideZeroBudgets: false,
      UseIncomeTotals: false,
      Accounts: [],
      incomes: [],
      EditIncome: {},
      TotalProfileIgnoreSelected: [],
      ProfilesToSplitWith: [],
      IncomeTableFields: [
        {
          key: "NameEdit",
          label: "Name",
          class: "col-1",
          sortDirection: "desc",
        },
        {
          key: "AmountEdit",
          label: "Amount",
          class: "col-1",
        },
        {
          key: "AccountEdit",
          label: "Account",
          class: "col-1",
        },
        {
          key: "NextDeposit",
          label: "Next Deposit",
          class: "col-1",
        },
        {
          key: "Actions",
          label: "Actions",
          class: "col-1",
        },
      ],
      profileTotalfields: [
        {
          key: "profile1total",
          label: "Profile #1",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile2total",
          label: "Profile #2",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile3total",
          label: "Profile #3",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile4total",
          label: "Profile #4",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile5total",
          label: "Profile #5",
          variant: "none",
          class: "col-1",
        },
      ],
      profilefields: [
        {
          key: "profile1",
          label: "Profile #1",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile2",
          label: "Profile #2",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile3",
          label: "Profile #3",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile4",
          label: "Profile #4",
          variant: "none",
          class: "col-1",
        },
        {
          key: "profile5",
          label: "Profile #5",
          variant: "none",
          class: "col-1",
        },
      ],
      profileCount: 1,
      fundingProfileFilter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      sortBy: "date",
      sortDesc: false,
      shownew: false,
      sortDirection: "asc",
      incomeFilter: null,
      income: [],
      Totals: [],
      budget: [],
      categories: [],
      recurringfreq: [],
      envelopes: [],
      // totalfields: [
      //   {
      //     key: "name",
      //     label: "",
      //     class: "col-1",
      //     sortDirection: "desc",
      //   },
      //   {
      //     key: "category.name",
      //     label: "",
      //     class: "col-1",
      //     sortDirection: "desc",
      //   },

      //   {
      //     key: "budget.amount",
      //     label: "",
      //     variant: "none",
      //     class: "col-1",
      //   },

      //   {
      //     key: "ProfileTotal",
      //     label: "",
      //     variant: "none",
      //     class: "col-1",
      //   },
      //   {
      //     key: "profile1total",
      //     label: "Profile #1",
      //     variant: "none",
      //     class: "col-1",
      //   },
      //   {
      //     key: "profile2total",
      //     label: "Profile #2",
      //     variant: "none",
      //     class: "col-1",
      //   },
      //   {
      //     key: "profile3total",
      //     label: "Profile #3",
      //     variant: "none",
      //     class: "col-1",
      //   },
      // ],
    };
  },
  created() {
    this.refresh();
  },
  computed: {
    FilteredEnvelopes() {
      return this.envelopes.filter((i) => {
        if (this.HideZeroBudgets) {
          return i.budget.amount > 0;
        } else {
          return true;
        }
      });
    },
    IncomesTotal() {
      return Number(this.incomes.reduce((a, b) => a + b.amount, 0)).toFixed(2);
    },
    AccountOptions() {
      if (this.Accounts) {
        return this.Accounts.map((i) => ({
          ...i,
          value: i.id,
          text: ` ${i.name}`,
        }));
      } else {
        return [{
          value: null,
          text: "No Accounts Available",
        }];
      }
    },
    TotalProfileIgnoreOptions() {
      return Array.from({ length: this.profileCount }, (_, i) => ({ text: `Profile ${i + 1}`, value: i + 1 }));
    },
    envelopefields() {
      return [
        {
          key: "category.name",
          label: "Category",
          class: "col-1",
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "Name",
          class: "col-1",
          sortDirection: "desc",
        },
        {
          key: "budgetamount",
          label: "Budget",
          variant: "none",
          class: "col-1",
        },

        {
          key: "ProfileTotal",
          label: "Total",
          variant: "none",
          class: "col-1",
        }
      ].concat(this.profilefields);
    },
    totalfields() {
      return [
        {
          key: "name",
          label: "",
          class: "col-1",
          sortDirection: "desc",
        },
        {
          key: "second",
          label: "",
          class: "col-1",
          sortDirection: "desc",
        },

        {
          key: "budget.amount",
          label: "",
          variant: "none",
          class: "col-1",
        },

        {
          key: "ProfileTotal",
          label: "",
          variant: "none",
          class: "col-1",
        },
      ].concat(this.profileTotalfields);
    }
  },
};
</script>
<style scoped></style>


<style scoped>
.bottom-row-style {
  padding: 15px !important;
  background-color: #ffffff;
}

.bottom-row-style-button {
  padding: 15px 0 15px 5px !important;
  background-color: #ffffff;
}

.table-scroll-fix .table-responsive {
  overflow-x: unset !important;
}
</style>
